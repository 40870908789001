import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';

import { Button } from '@material-ui/core';

interface Props {
  initialValues: {
    [key: string]: string;
    id: string;
    __typename: string;
  };
}

export default function UserForm({ initialValues }: Props) {
  const { id, __typename, ...filteredValues } = initialValues;
  const [sendForm] = useMutation(gql`
    mutation updateUser($id: String, $values: UserInput) {
      updateUser(id: $id, values: $values) {
        name
        nickname
        email
      }
    }
  `);
  return (
    <Formik
      initialValues={filteredValues}
      onSubmit={values => {
        sendForm({ variables: { id, values } });
      }}
    >
      {() => (
        <Form>
          <Field
            component={TextField}
            label="Email address"
            name="email"
            placeholder="Email address"
            type="email"
          />
          <ErrorMessage name="email" />
          <Field
            component={TextField}
            label="User name"
            name="name"
            placeholder="User name"
          />
          <ErrorMessage name="name" />
          <Field
            component={TextField}
            label="Nickname"
            name="nickname"
            placeholder="User nickname"
          />
          <ErrorMessage name="nickname" />
          <Button type="submit" variant="contained">
            {'Update'}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
