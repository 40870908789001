import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Router, RouteComponentProps } from '@reach/router';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { useAuthentication } from '../components/Authentication';

import UserForm from '../components/Forms/UserForm';

const userQuery = gql`
  query getUser($id: String) {
    user(id: $id) {
      id
      name
      nickname
      email
    }
  }
`;

interface HomeProps extends RouteComponentProps {
  user: {
    sub: string;
    name: string;
  };
  accessToken?: string;
}

const Home = ({ user, accessToken }: HomeProps) => {
  const { loading, data } = useQuery(userQuery, {
    variables: { id: user.sub },
    context: { accessToken },
  });

  return (
    <>
      <p>{`Hi, ${user.name ? user.name : 'friend'}`}</p>
      {!loading && data && <UserForm initialValues={data.user} />}
    </>
  );
};

const Settings: React.FunctionComponent<RouteComponentProps> = () => (
  <p>{'Settings'}</p>
);
const Billing: React.FunctionComponent<RouteComponentProps> = () => (
  <p>{'Billing'}</p>
);

export default function Account() {
  const { user, accessToken, loading, isAuthenticated } = useAuthentication();

  if (loading || !isAuthenticated) {
    return 'Authenticating';
  }

  return (
    <Layout subtitle="Vos informations en un coup d'oeil">
      <Seo title={'CRPM - Compte utilisateur'} />
      <Router>
        <Home accessToken={accessToken} path="/account" user={user} />
        <Settings path="/account/settings" />
        <Billing path="/account/billing" />
      </Router>
    </Layout>
  );
}
